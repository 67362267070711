import React from "react";
import {
  Box,
  Button,
  Grid,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import Seo from "components/Seo";
import Container from "components/ui/Container";
import * as styles from "styles/pricing.module.scss";
import { Link } from "gatsby";

const PricingPage: React.FC = () => {
  return (
    <Box py={10} backgroundColor="#FFF8EF">
      <Seo
        title="AiHouse Software Subscription Price"
        description="We provide software subscription at affordable price to experience the AiHouse 3D interior design visualization and realistic renderings software for interior designers and furnishing brands with immersive experience. You can simplify your design, realistic renderings and panorama are generated and shared with customers to boost your business."
        keywords="interior design software, interior design rendering software, 3D interior and exterior design rendering software, online floor plan design software, cloud-based interior design and rendering software platform, online 4K rendering software"
      />

      <Container fluid>
        <Stack spacing={10}>
          <Stack spacing={6} alignItems="center">
            <Text textAlign="center" fontSize="3xl">
              AiHouse Cloud Design Software <br />
              PRO Subscription Pricing!
            </Text>

            <Grid
              maxW="6xl"
              gap={4}
              templateColumns={{
                base: "repeat(1, 1fr)",
                sm: "repeat(2, 1fr)",
                md: "repeat(3, 1fr)",
              }}
            >
              <Stack
                borderColor="yellow.500"
                borderWidth={2}
                borderTopWidth={6}
                rounded="xl"
                p={9}
                spacing={10}
                textAlign="center"
                alignItems="center"
                shadow="md"
                transform="translate(0, 0)"
                _hover={{ transform: "translate(0, -2px)", shadow: "lg" }}
                transitionProperty="all"
                transitionDuration="200ms"
                transitionTimingFunction="linear"
                userSelect="none"
              >
                <Text fontSize="3xl">Monthly</Text>
                <Text color="gray.500">Unlimited projects & renders</Text>
                <Text fontWeight="bold" fontSize="3xl" color="gray.600">
                  ₹3276
                </Text>
                <Text fontWeight="medium" color="gray.400" fontSize="lg">
                  (18% GST Included)
                </Text>
                <Text color="gray.500">₹3276/Month | 30 Days</Text>
                <Button
                  colorScheme="orange"
                  size="lg"
                  rounded="full"
                  as={Link}
                  to="/contact-us/?pricingQuery=Monthly"
                >
                  Get Query
                </Button>
              </Stack>
     
               <Stack
                borderColor="yellow.500"
                borderWidth={2}
                borderTopWidth={6}
                rounded="xl"
                p={9}
                spacing={10}
                textAlign="center"
                alignItems="center"
                shadow="md"
                transform="translate(0, 0)"
                _hover={{ transform: "translate(0, -2px)", shadow: "lg" }}
                transitionProperty="all"
                transitionDuration="200ms"
                transitionTimingFunction="linear"
                userSelect="none"
              >
                <Text fontSize="3xl">Half Yearly</Text>
                <Text color="gray.500">Unlimited projects & renders</Text>
                <Text fontWeight="bold" fontSize="3xl" color="gray.600">
                  ₹15120
                </Text>
                <Text fontWeight="medium" color="gray.400" fontSize="lg">
                  (18% GST Included)
                </Text>
                <Text color="gray.500">₹2520/Month | 180 Days</Text>
                <Button
                  colorScheme="orange"
                  size="lg"
                  rounded="full"
                  as={Link}
                  to="/contact-us/?pricingQuery=Quarterly"
                >
                  Get Query
                </Button>
              </Stack>
              <Stack
                borderColor="yellow.500"
                borderWidth={2}
                borderTopWidth={6}
                rounded="xl"
                p={9}
                spacing={10}
                textAlign="center"
                alignItems="center"
                shadow="md"
                transform="translate(0, 0)"
                _hover={{ transform: "translate(0, -2px)", shadow: "lg" }}
                transitionProperty="all"
                transitionDuration="200ms"
                transitionTimingFunction="linear"
                userSelect="none"
              >
                <Text fontSize="3xl">Yearly</Text>
                <Text color="gray.500">Unlimited projects & renders</Text>
                <Text fontWeight="bold" fontSize="3xl" color="gray.600">
                  ₹25200
                </Text>
                <Text fontWeight="medium" color="gray.400" fontSize="lg">
                  (18% GST Included)
                </Text>
                <Text color="gray.500">₹2100/Month | 365 Days</Text>
                <Button
                  colorScheme="orange"
                  size="lg"
                  rounded="full"
                  as={Link}
                  to="/contact-us/?pricingQuery=Yearly"
                >
                  Get Query
                </Button>
              </Stack>
            </Grid>
            {/* Intro Box For VIP Subscription start */}
            <Text textAlign="center" fontSize="3xl">
              AiHouse Cloud Design Software <br />
              VIP Subscription Pricing!
            </Text>
            <Grid
              maxW="6xl"
              gap={4}
              templateColumns={{
                base: "repeat(1, 1fr)",
                sm: "repeat(2, 1fr)",
                md: "repeat(3, 1fr)",
              }}
            >
              <Stack
                borderColor="yellow.500"
                borderWidth={2}
                borderTopWidth={6}
                rounded="xl"
                p={9}
                spacing={10}
                textAlign="center"
                alignItems="center"
                shadow="md"
                transform="translate(0, 0)"
                _hover={{ transform: "translate(0, -2px)", shadow: "lg" }}
                transitionProperty="all"
                transitionDuration="200ms"
                transitionTimingFunction="linear"
                userSelect="none"
              >
                <Text fontSize="3xl">Monthly</Text>
                <Text color="gray.500">Unlimited projects & renders</Text>
                <Text fontWeight="bold" fontSize="3xl" color="gray.600">
                  ₹6300
                </Text>
                <Text fontWeight="medium" color="gray.400" fontSize="lg">
                  (18% GST Included)
                </Text>
                <Text color="gray.500">₹6300/Month | 30 Days</Text>
                <Button
                  colorScheme="orange"
                  size="lg"
                  rounded="full"
                  as={Link}
                  to="/contact-us/?pricingQuery=Monthly"
                >
                  Get Query
                </Button>
              </Stack>
     
               <Stack
                borderColor="yellow.500"
                borderWidth={2}
                borderTopWidth={6}
                rounded="xl"
                p={9}
                spacing={10}
                textAlign="center"
                alignItems="center"
                shadow="md"
                transform="translate(0, 0)"
                _hover={{ transform: "translate(0, -2px)", shadow: "lg" }}
                transitionProperty="all"
                transitionDuration="200ms"
                transitionTimingFunction="linear"
                userSelect="none"
              >
                <Text fontSize="3xl">Half Yearly</Text>
                <Text color="gray.500">Unlimited projects & renders</Text>
                <Text fontWeight="bold" fontSize="3xl" color="gray.600">
                  ₹30240
                </Text>
                <Text fontWeight="medium" color="gray.400" fontSize="lg">
                  (18% GST Included)
                </Text>
                <Text color="gray.500">₹5040/Month | 180 Days</Text>
                <Button
                  colorScheme="orange"
                  size="lg"
                  rounded="full"
                  as={Link}
                  to="/contact-us/?pricingQuery=Quarterly"
                >
                  Get Query
                </Button>
              </Stack>
              <Stack
                borderColor="yellow.500"
                borderWidth={2}
                borderTopWidth={6}
                rounded="xl"
                p={9}
                spacing={10}
                textAlign="center"
                alignItems="center"
                shadow="md"
                transform="translate(0, 0)"
                _hover={{ transform: "translate(0, -2px)", shadow: "lg" }}
                transitionProperty="all"
                transitionDuration="200ms"
                transitionTimingFunction="linear"
                userSelect="none"
              >
                <Text fontSize="3xl">Yearly</Text>
                <Text color="gray.500">Unlimited projects & renders</Text>
                <Text fontWeight="bold" fontSize="3xl" color="gray.600">
                  ₹50400
                </Text>
                <Text fontWeight="medium" color="gray.400" fontSize="lg">
                  (18% GST Included)
                </Text>
                <Text color="gray.500">₹4200/Month | 365 Days</Text>
                <Button
                  colorScheme="orange"
                  size="lg"
                  rounded="full"
                  as={Link}
                  to="/contact-us/?pricingQuery=Yearly"
                >
                  Get Query
                </Button>
              </Stack>
            </Grid>
            {/* Intro Box For VIP Subscription End */}
          </Stack>
          

          <Stack spacing={6} alignItems="center">
            <Text fontSize="3xl" textAlign="center">
              All Features of PRO
            </Text>

            <Box overflowX="auto" width="full" pr={1}>
              <Table
                variant="simple"
                colorScheme="orange"
                size="lg"
                fontSize="lg"
                fontWeight="light"
                className={styles.featureTable}
              >
                <Thead>
                  <Tr>
                    <Th></Th>
                    <Th>Monthly</Th>
                    {/* <Th>Quarterly</Th> */}
                    <Th>Half Year</Th>
                    <Th>Yearly</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td
                      backgroundColor="orange.400"
                      fontWeight="bold"
                      fontSize="xl"
                      colSpan={5}
                      style={{ textAlign: "left" }}
                    >
                      Design & render
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Rendering Speed Upgrade</Td>
                    <Td>√</Td>
                    {/* <Td>√</Td> */}
                    <Td>√</Td>
                    <Td>√</Td>
                  </Tr>
                  <Tr>
                    <Td
                      backgroundColor="orange.400"
                      fontWeight="bold"
                      fontSize="xl"
                      colSpan={5}
                      style={{ textAlign: "left" }}
                    >
                      Customizations
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      Storages for kitchen, bathroom, bedroom, living & dining
                      area…
                    </Td>
                    <Td>√</Td>
                    {/* <Td>√</Td> */}
                    <Td>√</Td>
                    <Td>√</Td>
                  </Tr>
                  <Tr>
                    <Td>Tiling</Td>
                    <Td>√</Td>
                    {/* <Td>√</Td> */}
                    <Td>√</Td>
                    <Td>√</Td>
                  </Tr>
                  <Tr>
                    <Td>Ceiling</Td>
                    <Td>√</Td>
                    {/* <Td>√</Td> */}
                    <Td>√</Td>
                    <Td>√</Td>
                  </Tr>
                  <Tr>
                    <Td>Shower Room</Td>
                    <Td>√</Td>
                    {/* <Td>√</Td> */}
                    <Td>√</Td>
                    <Td>√</Td>
                  </Tr>
                  <Tr>
                    <Td>Aluminum Door & Window</Td>
                    <Td>√</Td>
                    {/* <Td>√</Td> */}
                    <Td>√</Td>
                    <Td>√</Td>
                  </Tr>
                  <Tr>
                    <Td>Wardrobes</Td>
                    <Td>√</Td>
                    {/* <Td>√</Td> */}
                    <Td>√</Td>
                    <Td>√</Td>
                  </Tr>
                  <Tr>
                    <Td>Utility</Td>
                    <Td>√</Td>
                    {/* <Td>√</Td> */}
                    <Td>√</Td>
                    <Td>√</Td>
                  </Tr>
                  <Tr>
                    <Td>Vanities</Td>
                    <Td>√</Td>
                    {/* <Td>√</Td> */}
                    <Td>√</Td>
                    <Td>√</Td>
                  </Tr>

                   <Tr>
                    <Td
                      backgroundColor="orange.400"
                      fontWeight="bold"
                      fontSize="xl"
                      colSpan={5}
                      style={{ textAlign: "left" }}
                    >
                      Standard Picture Rendering
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>SD (Aerial)</Td>
                    <Td>Unlimited</Td>
                    {/* <Td>Unlimited</Td> */}
                    <Td>Unlimited</Td>
                    <Td>Unlimited</Td>
                  </Tr>
                  <Tr>
                    <Td>HD (Aerial)</Td>
                    <Td>Unlimited</Td>
                    {/* <Td>Unlimited</Td> */}
                    <Td>Unlimited</Td>
                    <Td>Unlimited</Td>
                  </Tr>
                  <Tr>
                    <Td>UHD (Aerial)</Td>
                    <Td>Unlimited</Td>
                    {/* <Td>Unlimited</Td> */}
                    <Td>Unlimited</Td>
                    <Td>Unlimited</Td>
                  </Tr>
                  <Tr>
                    <Td>4K (Aerial)</Td>
                    <Td>Unlimited</Td>
                    {/* <Td>Unlimited</Td> */}
                    <Td>Unlimited</Td>
                    <Td>Unlimited</Td>
                  </Tr>
                  <Tr>
                    <Td>8K (Aerial)</Td>
                    <Td>0</Td>
                    {/* <Td>50/Month</Td> */}
                    <Td>0</Td>
                    <Td>0</Td>
                  </Tr>
                  <Tr>
                    <Td>SD (Non-Aerial)</Td>
                    <Td>Unlimited</Td>
                    {/* <Td>Unlimited</Td> */}
                    <Td>Unlimited</Td>
                    <Td>Unlimited</Td>
                  </Tr>
                  <Tr>
                    <Td>HD (Non-Aerial)</Td>
                    <Td>Unlimited</Td>
                    {/* <Td>Unlimited</Td> */}
                    <Td>Unlimited</Td>
                    <Td>Unlimited</Td>
                  </Tr>
                  <Tr>
                    <Td>UHD (Non-Aerial)</Td>
                    <Td>Unlimited</Td>
                    {/* <Td>Unlimited</Td> */}
                    <Td>Unlimited</Td>
                    <Td>Unlimited</Td>
                  </Tr>
                  <Tr>
                    <Td>4K (Non-Aerial)</Td>
                    <Td>Unlimited</Td>
                    {/* <Td>Unlimited</Td> */}
                    <Td>Unlimited</Td>
                    <Td>Unlimited</Td>
                  </Tr>
                  <Tr>
                    <Td>8K (Non-Aerial)</Td>
                    <Td>0</Td>
                    {/* <Td>50/Month</Td> */}
                    <Td>0</Td>
                    <Td>0</Td>
                  </Tr>
                  
                  <Tr>
                    <Td
                      backgroundColor="orange.400"
                      fontWeight="bold"
                      fontSize="xl"
                      colSpan={5}
                      style={{ textAlign: "left" }}
                    >
                      Standard Panorama Rendering
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>4K</Td>
                    <Td>Unlimited</Td>
                    {/* <Td>Unlimited</Td> */}
                    <Td>Unlimited</Td>
                    <Td>Unlimited</Td>
                  </Tr>
                  <Tr>
                    <Td>8K</Td>
                    <Td>Unlimited</Td>
                    {/* <Td>Unlimited</Td> */}
                    <Td>Unlimited</Td>
                    <Td>Unlimited</Td>
                  </Tr>
                  <Tr>
                    <Td>16K</Td>
                    <Td>6/Month</Td>
                    {/* <Td>50/Month</Td> */}
                    <Td>6/Month</Td>
                    <Td>6/Month</Td>
                  </Tr>
                  
                  <Tr>
                    <Td
                      backgroundColor="orange.400"
                      fontWeight="bold"
                      fontSize="xl"
                      colSpan={5}
                      style={{ textAlign: "left" }}
                    >
                      Video & Animation Rendering
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>10 Second SD</Td>
                    <Td>0</Td>
                    {/* <Td>30/Month</Td> */}
                    <Td>0</Td>
                    <Td>0</Td>
                  </Tr>
                  <Tr>
                    <Td>10 Second HD</Td>
                    <Td>0</Td>
                    {/* <Td>30/Month</Td> */}
                    <Td>0</Td>
                    <Td>0</Td>
                  </Tr>
                  <Tr>
                    <Td>10 Second UDH</Td>
                    <Td>0</Td>
                    {/* <Td>12/Month</Td> */}
                    <Td>0</Td>
                    <Td>0</Td>
                  </Tr>
                  <Tr>
                    <Td>10 Second 2K</Td>
                    <Td>0</Td>
                    {/* <Td>0</Td> */}
                    <Td>0</Td>
                    <Td>0</Td>
                  </Tr>
                  <Tr>
                    <Td>10 Second 4K</Td>
                    <Td>0</Td>
                    {/* <Td>0</Td> */}
                    <Td>0</Td>
                    <Td>0</Td>
                  </Tr>
                  
                  <Tr>
                    <Td
                      backgroundColor="orange.400"
                      fontWeight="bold"
                      fontSize="xl"
                      colSpan={5}
                      style={{ textAlign: "left" }}
                    >
                      Cinematic Rendering
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Picture-SD</Td>
                    <Td>0</Td>
                    {/* <Td>300/Month</Td> */}
                    <Td>0</Td>
                    <Td>0</Td>
                  </Tr>
                  <Tr>
                    <Td>Picture-4K</Td>
                    <Td>0</Td>
                    {/* <Td>300/Month</Td> */}
                    <Td>0</Td>
                    <Td>0</Td>
                  </Tr>
                  <Tr>
                    <Td>Picture-8K</Td>
                    <Td>0</Td>
                    {/* <Td>0</Td> */}
                    <Td>0</Td>
                    <Td>0</Td>
                  </Tr>
                  <Tr>
                    <Td>Panorama-6K</Td>
                    <Td>0</Td>
                    {/* <Td>300/Month</Td> */}
                    <Td>0</Td>
                    <Td>0</Td>
                  </Tr>
                </Tbody>
              </Table>
            </Box>
          </Stack>
          {/* Feature of VIP Start..............................   */}
          <Stack spacing={6} alignItems="center">
            <Text fontSize="3xl" textAlign="center">
              All Features of VIP
            </Text>

            <Box overflowX="auto" width="full" pr={1}>
              <Table
                variant="simple"
                colorScheme="orange"
                size="lg"
                fontSize="lg"
                fontWeight="light"
                className={styles.featureTable}
              >
                <Thead>
                  <Tr>
                    <Th></Th>
                    <Th>Monthly</Th>
                    {/* <Th>Quarterly</Th> */}
                    <Th>Half Year</Th>
                    <Th>Yearly</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td
                      backgroundColor="orange.400"
                      fontWeight="bold"
                      fontSize="xl"
                      colSpan={5}
                      style={{ textAlign: "left" }}
                    >
                      Design & render
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Render Speed Upgrade</Td>
                    <Td>√</Td>
                    {/* <Td>√</Td> */}
                    <Td>√</Td>
                    <Td>√</Td>
                  </Tr>

                  <Tr>
                    <Td
                      backgroundColor="orange.400"
                      fontWeight="bold"
                      fontSize="xl"
                      colSpan={5}
                      style={{ textAlign: "left" }}
                    >
                      Customizations
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      Storages for kitchen, bathroom, bedroom, living & dining
                      area…
                    </Td>
                    <Td>√</Td>
                    {/* <Td>√</Td> */}
                    <Td>√</Td>
                    <Td>√</Td>
                  </Tr>
                  <Tr>
                    <Td>Tiling</Td>
                    <Td>√</Td>
                    {/* <Td>√</Td> */}
                    <Td>√</Td>
                    <Td>√</Td>
                  </Tr>
                  <Tr>
                    <Td>Ceiling</Td>
                    <Td>√</Td>
                    {/* <Td>√</Td> */}
                    <Td>√</Td>
                    <Td>√</Td>
                  </Tr>
                  <Tr>
                    <Td>Shower Room</Td>
                    <Td>√</Td>
                    {/* <Td>√</Td> */}
                    <Td>√</Td>
                    <Td>√</Td>
                  </Tr>
                  <Tr>
                    <Td>Aluminum Door & Window</Td>
                    <Td>√</Td>
                    {/* <Td>√</Td> */}
                    <Td>√</Td>
                    <Td>√</Td>
                  </Tr>
                  <Tr>
                    <Td>Wardrobes</Td>
                    <Td>√</Td>
                    {/* <Td>√</Td> */}
                    <Td>√</Td>
                    <Td>√</Td>
                  </Tr>
                  <Tr>
                    <Td>Utility</Td>
                    <Td>√</Td>
                    {/* <Td>√</Td> */}
                    <Td>√</Td>
                    <Td>√</Td>
                  </Tr>
                  <Tr>
                    <Td>Vanities</Td>
                    <Td>√</Td>
                    {/* <Td>√</Td> */}
                    <Td>√</Td>
                    <Td>√</Td>
                  </Tr>

                  <Tr>
                    <Td
                      backgroundColor="orange.400"
                      fontWeight="bold"
                      fontSize="xl"
                      colSpan={5}
                      style={{ textAlign: "left" }}
                    >
                      Standard Picture Rendering
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>SD (Aerial)</Td>
                    <Td>Unlimited</Td>
                    {/* <Td>Unlimited</Td> */}
                    <Td>Unlimited</Td>
                    <Td>Unlimited</Td>
                  </Tr>
                  <Tr>
                    <Td>HD (Aerial)</Td>
                    <Td>Unlimited</Td> 
                    {/* <Td>Unlimited</Td> */}
                    <Td>Unlimited</Td>
                    <Td>Unlimited</Td>
                  </Tr>
                  <Tr>
                    <Td>UHD (Aerial)</Td>
                    <Td>Unlimited</Td>
                    {/* <Td>Unlimited</Td> */}
                    <Td>Unlimited</Td>
                    <Td>Unlimited</Td>
                  </Tr>
                  <Tr>
                    <Td>4K (Aerial)</Td>
                    <Td>Unlimited</Td>
                    {/* <Td>Unlimited</Td> */}
                    <Td>Unlimited</Td>
                    <Td>Unlimited</Td>
                  </Tr>
                  <Tr>
                    <Td>8K (Aerial)</Td>
                    <Td>50/Month</Td>
                    {/* <Td>50/Month</Td> */}
                    <Td>50/Month</Td>
                    <Td>50/Month</Td>
                  </Tr>
                  <Tr>
                    <Td>SD (Non-Aerial)</Td>
                    <Td>Unlimited</Td>
                    {/* <Td>Unlimited</Td> */}
                    <Td>Unlimited</Td>
                    <Td>Unlimited</Td>
                  </Tr>
                  <Tr>
                    <Td>HD (Non-Aerial)</Td>
                    <Td>Unlimited</Td>
                    {/* <Td>Unlimited</Td> */}
                    <Td>Unlimited</Td>
                    <Td>Unlimited</Td>
                  </Tr>
                  <Tr>
                    <Td>UHD (Non-Aerial)</Td>
                    <Td>Unlimited</Td>
                    {/* <Td>Unlimited</Td> */}
                    <Td>Unlimited</Td>
                    <Td>Unlimited</Td>
                  </Tr>
                  <Tr>
                    <Td>4K (Non-Aerial)</Td>
                    <Td>Unlimited</Td>
                    {/* <Td>Unlimited</Td> */}
                    <Td>Unlimited</Td>
                    <Td>Unlimited</Td>
                  </Tr>
                  <Tr>
                    <Td>8K (Non-Aerial)</Td>
                    <Td>50/Month</Td>
                    {/* <Td>50/Month</Td> */}
                    <Td>50/Month</Td>
                    <Td>50/Month</Td>
                  </Tr>
                  
                  <Tr>
                    <Td
                      backgroundColor="orange.400"
                      fontWeight="bold"
                      fontSize="xl"
                      colSpan={5}
                      style={{ textAlign: "left" }}
                    >
                      Standard Panorama Rendering
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>4K</Td>
                    <Td>Unlimited</Td>
                    {/* <Td>Unlimited</Td> */}
                    <Td>Unlimited</Td>
                    <Td>Unlimited</Td>
                  </Tr>
                  <Tr>
                    <Td>8K</Td>
                    <Td>Unlimited</Td>
                    {/* <Td>Unlimited</Td> */}
                    <Td>Unlimited</Td>
                    <Td>Unlimited</Td>
                  </Tr>
                  <Tr>
                    <Td>16K</Td>
                    <Td>50/Month</Td>
                    {/* <Td>50/Month</Td> */}
                    <Td>50/Month</Td>
                    <Td>50/Month</Td>
                  </Tr>
                  
                  <Tr>
                    <Td
                      backgroundColor="orange.400"
                      fontWeight="bold"
                      fontSize="xl"
                      colSpan={5}
                      style={{ textAlign: "left" }}
                    >
                      Video Rendering
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>10 Second SD</Td>
                    <Td>30/Month</Td>
                    {/* <Td>30/Month</Td> */}
                    <Td>30/Month</Td>
                    <Td>30/Month</Td>
                  </Tr>
                  <Tr>
                    <Td>10 Second HD</Td>
                    <Td>30/Month</Td>
                    {/* <Td>30/Month</Td> */}
                    <Td>30/Month</Td>
                    <Td>30/Month</Td>
                  </Tr>
                  <Tr>
                    <Td>10 Second UDH</Td>
                    <Td>12/Month</Td>
                    {/* <Td>12/Month</Td> */}
                    <Td>12/Month</Td>
                    <Td>12/Month</Td>
                  </Tr>
                  <Tr>
                    <Td>10 Second 2K</Td>
                    <Td>0</Td>
                    {/* <Td>0</Td> */}
                    <Td>0</Td>
                    <Td>0</Td>
                  </Tr>
                  <Tr>
                    <Td>10 Second 4K</Td>
                    <Td>0</Td>
                    {/* <Td>0</Td> */}
                    <Td>0</Td>
                    <Td>0</Td>
                  </Tr>
                  
                  <Tr>
                    <Td
                      backgroundColor="orange.400"
                      fontWeight="bold"
                      fontSize="xl"
                      colSpan={5}
                      style={{ textAlign: "left" }}
                    >
                      Cinematic Rendering
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Picture-SD</Td>
                    <Td>300/Month</Td>
                    {/* <Td>300/Month</Td> */}
                    <Td>300/Month</Td>
                    <Td>300/Month</Td>
                  </Tr>
                  <Tr>
                    <Td>Picture-4K</Td>
                    <Td>300/Month</Td>
                    {/* <Td>300/Month</Td> */}
                    <Td>300/Month</Td>
                    <Td>300/Month</Td>
                  </Tr>
                  <Tr>
                    <Td>Picture-8K</Td>
                    <Td>0</Td>
                    {/* <Td>0</Td> */}
                    <Td>0</Td>
                    <Td>0</Td>
                  </Tr>
                  <Tr>
                    <Td>Panorama-6K</Td>
                    <Td>300/Month</Td>
                    {/* <Td>300/Month</Td> */}
                    <Td>300/Month</Td>
                    <Td>300/Month</Td>
                  </Tr>
                </Tbody>
              </Table>
            </Box>
          </Stack>
          {/* Feature of VIP End */}
        </Stack>
      </Container>
    </Box>
  );
};

export default PricingPage;
